@import url(https://fonts.googleapis.com/css?family=Raleway);
@import "_animate";

itasquiz,
.itasquiz{
	font-family: "Raleway", "Open Sans", Arial;
	font-weight: 400;
	font-size: 21px;
	line-height: 1.58;
	padding:1.5em;
	background: #fff;
	min-height: 100px;
	width:100%;
	text-align: center;
	box-sizing: border-box;
	display:block;
	position: relative;

	*,*:after,*:before{
		box-sizing: inherit;
	}

	&:empty:before{
		content: '';
		display:block;
		width:30px;
		height: 30px;
		border: 3px solid #999;
		border-left-color: transparent;
		border-radius: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -15px;
		animation: itas-spin .5s infinite linear;
	}

	@keyframes itas-spin{
		to{
			transform: rotate(1turn);
		}
	}

	h1{
		line-height: 1.5;
		font-size: 1.3em;
		font-weight: bold;
		margin:0;

		img{
			max-width: 150px;
			display:block;
			margin: 0 auto 15px auto;
		}
	}

	hr{
		max-width: 150px;
		border: none;
		border-top: 1px solid;
		border-top-color: #eaeaea;
		margin: 1em auto;
	}

	p{
		margin:0!important;
		padding:0!important;
		line-height: inherit!important;
		font-size: inherit!important;
		font-weight: inherit;
	}

	.description{
		padding: 0 1em;
		line-height: 1.5;
	}

	button{
		border:none;
		outline:none;
		font-family: inherit;
		font-size: inherit;
		margin:1em auto 0 auto;
		display:block;
		padding:10px 60px;
		cursor:pointer;
		border-radius: .5em;
		color:white;
		background-color: #63C572;
		transition: background-color .2s ease;

		&:hover{
			background-color: darken(#63C572,10%);
		}
	}

	.question-box{
		text-align: center;
		padding: .5em 0;
	}

	.question{
		color:#333;
		line-height: 1.3;
		text-align: center;
		font-size:20px;
		margin:20px 0;
		width:100%;

		p{
			margin:0;
		}

		b{
			display:block;
			width:100%;
		}

		i{
			font-size:.8em;
			font-style: normal;
		}
	}


	.costs{
		display: flex;
		align-items: center;
		color: #FFF;
		background-color: #63C572;
		text-align: center;
		padding: .75em 1em;
		margin:10px;
		border-radius: 5px;

		&.negative{
			background-color: #FF7869;
		}

		img{
			width: 20px;
			vertical-align: middle;
			margin-right: 20px;
		}
	}

	.quiz-progress{
		width: 100%;
		max-width: 200px;
		display: flex;
		align-items: center;
		margin:10px;
		text-align: center;
		font-size: .7em;

		p{
			margin: 0 1em 0 0!important;
			white-space: nowrap;
		}

		.quiz-progress-container{
			height: .5em;
			background-color: #eaeaea;
			width: 100%;
			border-radius: 10px;
			position: relative;
		}

		.quiz-progress-bar{
			position:absolute;
			left:0;
			top:0;
			height:100%;
			border-radius: inherit;
			background:#63C572;
			transition: width .2s ease;
		}

	}

	.quiz-result{
		text-align: center;

		img{
			max-width: 150px;
			display:block;
			margin: 0 auto 15px auto;
		}

	}

	.submit-container{
		display:flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap:wrap;
		width:100%;
		margin-top:20px;
	}

	.fasit-container{
		* + * {
			margin-top: .5em!important;
		}
	}

	ol.answers{
		padding:0!important;
		margin:0!important;
		list-style: none!important;

		display:flex;
		flex-wrap:wrap;

		li{
			flex-grow:1;
			margin: 10px;
			padding: 0!important;
			line-height: inherit!important;
			transition: transform .2s ease;

			label{
				background:white;
				display:block;
				padding:20px 20px 20px 80px;
				font-weight: inherit;
				background: #eaeaea;
				border-radius: .5em;
				color:#333;
				cursor:pointer;
				position:relative;
				transition:background-color .1s ease;

				@media(max-width: 750px){
					font-size: .75em;
				}

				&:hover{
					background: darken(#eaeaea,5%);
				}

				&:active{
					background: darken(#eaeaea,10%);
				}

				&:before{
					position:absolute;
					background-color: darken(#eaeaea,35%);
					color: #FFF;
					height: 100%;
					border-radius: .5em 0 0 .5em;
					top: 0;
					left: 0;
					width: 60px;
					display:flex;
					justify-content:center;
					align-items:center;
					transition:background-color .2s ease;
				}
			}

			&:nth-child(1) label:before{
				content: 'a.'
			}

			&:nth-child(2) label:before{
				content: 'b.'
			}
			&:nth-child(3) label:before{
				content: 'c.'
			}
			&:nth-child(4) label:before{
				content: 'd.'
			}
			&:nth-child(5) label:before{
				content: 'e.'
			}

			&:nth-child(6) label:before{
				content: 'f.'
			}
			&:nth-child(7) label:before{
				content: 'g.'
			}
			&:nth-child(8) label:before{
				content: 'h.'
			}
		}
	}
}




.aspect-ratio(@w,@h){
	position: relative;

	&:before{
		display:block;
		content:'';
		width:100%;
		padding-top: (@h / @w) * 100%;
	}

	> *{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		width:100%;
		height:100%;
	}
}
