@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v34/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrQ.ttf) format('truetype');
}
.animated-delay > .animated:nth-child(10) {
  animation-delay: 0.9s;
}
.animated-delay > .animated:nth-child(9) {
  animation-delay: 0.8s;
}
.animated-delay > .animated:nth-child(8) {
  animation-delay: 0.7s;
}
.animated-delay > .animated:nth-child(7) {
  animation-delay: 0.6s;
}
.animated-delay > .animated:nth-child(6) {
  animation-delay: 0.5s;
}
.animated-delay > .animated:nth-child(5) {
  animation-delay: 0.4s;
}
.animated-delay > .animated:nth-child(4) {
  animation-delay: 0.3s;
}
.animated-delay > .animated:nth-child(3) {
  animation-delay: 0.2s;
}
.animated-delay > .animated:nth-child(2) {
  animation-delay: 0.1s;
}
.animated-delay > .animated:nth-child(1) {
  animation-delay: 0s;
}
.delayed {
  animation-delay: 0.25s;
}
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.animated.fast {
  animation-duration: 0.1s;
}
.animated.infinite {
  animation-iteration-count: infinite;
}
.animated.hinge {
  animation-duration: 2s;
}
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: 0.75s;
}
.animated.flipOutX,
.animated.flipOutY {
  animation-duration: 0.75s;
}
@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}
@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  animation-name: flash;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  animation-name: pulse;
}
@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  animation-name: rubberBand;
}
@keyframes shake {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  animation-name: shake;
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  transform-origin: top center;
  animation-name: swing;
}
@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  animation-name: tada;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}
.wobble {
  animation-name: wobble;
}
@keyframes jello {
  11.1% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    transform: none;
  }
}
.jello {
  animation-name: jello;
  transform-origin: center;
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  animation-name: bounceIn;
}
@keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInDown {
  animation-name: bounceInDown;
}
@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInLeft {
  animation-name: bounceInLeft;
}
@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInRight {
  animation-name: bounceInRight;
}
@keyframes bounceInUp {
  0%,
  60%,
  75%,
  90%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  animation-name: bounceInUp;
}
@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  animation-name: bounceOut;
}
@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  animation-name: bounceOutDown;
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  animation-name: bounceOutLeft;
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  animation-name: bounceOutRight;
}
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  animation-name: bounceOutUp;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDownBig {
  animation-name: fadeInDownBig;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRightBig {
  animation-name: fadeInRightBig;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUpBig {
  animation-name: fadeInUpBig;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
}
.fadeOutDown {
  animation-name: fadeOutDown;
}
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
}
.fadeOutUp {
  animation-name: fadeOutUp;
}
@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px);
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInXsmall {
  backface-visibility: visible !important;
  animation-name: flipInXsmall;
  animation-duration: 0.2s;
}
@keyframes flipInXsmall {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    animation-timing-function: ease-out;
    opacity: 0;
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInX {
  backface-visibility: visible !important;
  animation-name: flipInX;
}
@keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInY {
  backface-visibility: visible !important;
  animation-name: flipInY;
}
.flipInYsmall {
  backface-visibility: visible !important;
  animation-name: flipInYsmall;
  animation-duration: 0.2s;
}
@keyframes flipInYsmall {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 20deg);
    animation-timing-function: ease-out;
    opacity: 0;
  }
  100% {
    transform: perspective(400px);
  }
}
@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}
@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  backface-visibility: visible !important;
  animation-name: flipOutY;
}
@keyframes lightSpeedIn {
  0% {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}
@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}
@keyframes rotateIn {
  0% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  animation-name: rotateIn;
}
@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}
@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  animation-name: rotateInDownRight;
}
@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}
@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  animation-name: rotateInUpRight;
}
@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }
  100% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  animation-name: rotateOut;
}
@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}
@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}
@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}
@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}
@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  animation-name: hinge;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.rollIn {
  animation-name: rollIn;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  animation-name: rollOut;
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  animation-name: zoomIn;
}
@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  animation-name: zoomInDown;
}
@keyframes zoomInLeft {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  animation-name: zoomInLeft;
}
@keyframes zoomInRight {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  animation-name: zoomInRight;
}
@keyframes zoomInUp {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  animation-name: zoomInUp;
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
.zoomOut {
  animation-name: zoomOut;
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  animation-name: zoomOutDown;
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center;
  }
}
.zoomOutLeft {
  animation-name: zoomOutLeft;
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center;
  }
}
.zoomOutRight {
  animation-name: zoomOutRight;
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  animation-name: zoomOutUp;
}
@keyframes slideInDown {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  animation-name: slideInDown;
}
@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  animation-name: slideInLeft;
}
@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  animation-name: slideInRight;
}
@keyframes slideInUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}
@keyframes slideOutDown {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}
.slideOutDown {
  animation-name: slideOutDown;
}
@keyframes slideOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}
.slideOutLeft {
  animation-name: slideOutLeft;
}
@keyframes slideOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}
.slideOutRight {
  animation-name: slideOutRight;
}
@keyframes slideOutUp {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}
.slideOutUp {
  animation-name: slideOutUp;
}
itasquiz,
.itasquiz {
  font-family: "Raleway", "Open Sans", Arial;
  font-weight: 400;
  font-size: 21px;
  line-height: 1.58;
  padding: 1.5em;
  background: #fff;
  min-height: 100px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  display: block;
  position: relative;
}
itasquiz *,
.itasquiz *,
itasquiz *:after,
.itasquiz *:after,
itasquiz *:before,
.itasquiz *:before {
  box-sizing: inherit;
}
itasquiz:empty:before,
.itasquiz:empty:before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  border: 3px solid #999;
  border-left-color: transparent;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -15px;
  animation: itas-spin 0.5s infinite linear;
}
@keyframes itas-spin {
  to {
    transform: rotate(1turn);
  }
}
itasquiz h1,
.itasquiz h1 {
  line-height: 1.5;
  font-size: 1.3em;
  font-weight: bold;
  margin: 0;
}
itasquiz h1 img,
.itasquiz h1 img {
  max-width: 150px;
  display: block;
  margin: 0 auto 15px auto;
}
itasquiz hr,
.itasquiz hr {
  max-width: 150px;
  border: none;
  border-top: 1px solid;
  border-top-color: #eaeaea;
  margin: 1em auto;
}
itasquiz p,
.itasquiz p {
  margin: 0!important;
  padding: 0!important;
  line-height: inherit!important;
  font-size: inherit!important;
  font-weight: inherit;
}
itasquiz .description,
.itasquiz .description {
  padding: 0 1em;
  line-height: 1.5;
}
itasquiz button,
.itasquiz button {
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  margin: 1em auto 0 auto;
  display: block;
  padding: 10px 60px;
  cursor: pointer;
  border-radius: 0.5em;
  color: white;
  background-color: #63C572;
  transition: background-color 0.2s ease;
}
itasquiz button:hover,
.itasquiz button:hover {
  background-color: #42b354;
}
itasquiz .question-box,
.itasquiz .question-box {
  text-align: center;
  padding: 0.5em 0;
}
itasquiz .question,
.itasquiz .question {
  color: #333;
  line-height: 1.3;
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
  width: 100%;
}
itasquiz .question p,
.itasquiz .question p {
  margin: 0;
}
itasquiz .question b,
.itasquiz .question b {
  display: block;
  width: 100%;
}
itasquiz .question i,
.itasquiz .question i {
  font-size: 0.8em;
  font-style: normal;
}
itasquiz .costs,
.itasquiz .costs {
  display: flex;
  align-items: center;
  color: #FFF;
  background-color: #63C572;
  text-align: center;
  padding: 0.75em 1em;
  margin: 10px;
  border-radius: 5px;
}
itasquiz .costs.negative,
.itasquiz .costs.negative {
  background-color: #FF7869;
}
itasquiz .costs img,
.itasquiz .costs img {
  width: 20px;
  vertical-align: middle;
  margin-right: 20px;
}
itasquiz .quiz-progress,
.itasquiz .quiz-progress {
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  margin: 10px;
  text-align: center;
  font-size: 0.7em;
}
itasquiz .quiz-progress p,
.itasquiz .quiz-progress p {
  margin: 0 1em 0 0!important;
  white-space: nowrap;
}
itasquiz .quiz-progress .quiz-progress-container,
.itasquiz .quiz-progress .quiz-progress-container {
  height: 0.5em;
  background-color: #eaeaea;
  width: 100%;
  border-radius: 10px;
  position: relative;
}
itasquiz .quiz-progress .quiz-progress-bar,
.itasquiz .quiz-progress .quiz-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: inherit;
  background: #63C572;
  transition: width 0.2s ease;
}
itasquiz .quiz-result,
.itasquiz .quiz-result {
  text-align: center;
}
itasquiz .quiz-result img,
.itasquiz .quiz-result img {
  max-width: 150px;
  display: block;
  margin: 0 auto 15px auto;
}
itasquiz .submit-container,
.itasquiz .submit-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}
itasquiz .fasit-container * + *,
.itasquiz .fasit-container * + * {
  margin-top: 0.5em !important;
}
itasquiz ol.answers,
.itasquiz ol.answers {
  padding: 0!important;
  margin: 0!important;
  list-style: none!important;
  display: flex;
  flex-wrap: wrap;
}
itasquiz ol.answers li,
.itasquiz ol.answers li {
  flex-grow: 1;
  margin: 10px;
  padding: 0!important;
  line-height: inherit!important;
  transition: transform 0.2s ease;
}
itasquiz ol.answers li label,
.itasquiz ol.answers li label {
  background: white;
  display: block;
  padding: 20px 20px 20px 80px;
  font-weight: inherit;
  background: #eaeaea;
  border-radius: 0.5em;
  color: #333;
  cursor: pointer;
  position: relative;
  transition: background-color 0.1s ease;
}
@media (max-width: 750px) {
  itasquiz ol.answers li label,
  .itasquiz ol.answers li label {
    font-size: 0.75em;
  }
}
itasquiz ol.answers li label:hover,
.itasquiz ol.answers li label:hover {
  background: #dddddd;
}
itasquiz ol.answers li label:active,
.itasquiz ol.answers li label:active {
  background: #d1d1d1;
}
itasquiz ol.answers li label:before,
.itasquiz ol.answers li label:before {
  position: absolute;
  background-color: #919191;
  color: #FFF;
  height: 100%;
  border-radius: 0.5em 0 0 0.5em;
  top: 0;
  left: 0;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
}
itasquiz ol.answers li:nth-child(1) label:before,
.itasquiz ol.answers li:nth-child(1) label:before {
  content: 'a.';
}
itasquiz ol.answers li:nth-child(2) label:before,
.itasquiz ol.answers li:nth-child(2) label:before {
  content: 'b.';
}
itasquiz ol.answers li:nth-child(3) label:before,
.itasquiz ol.answers li:nth-child(3) label:before {
  content: 'c.';
}
itasquiz ol.answers li:nth-child(4) label:before,
.itasquiz ol.answers li:nth-child(4) label:before {
  content: 'd.';
}
itasquiz ol.answers li:nth-child(5) label:before,
.itasquiz ol.answers li:nth-child(5) label:before {
  content: 'e.';
}
itasquiz ol.answers li:nth-child(6) label:before,
.itasquiz ol.answers li:nth-child(6) label:before {
  content: 'f.';
}
itasquiz ol.answers li:nth-child(7) label:before,
.itasquiz ol.answers li:nth-child(7) label:before {
  content: 'g.';
}
itasquiz ol.answers li:nth-child(8) label:before,
.itasquiz ol.answers li:nth-child(8) label:before {
  content: 'h.';
}
